import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
	...SectionTilesProps.types
};

const defaultProps = {
	...SectionTilesProps.defaults
};
const FeaturesTiles = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	pushLeft,
	...props
}) => {
	const outerClasses = classNames(
		'features-tiles section',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'features-tiles-inner section-inner pt-0',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const tilesClasses = classNames(
		'tiles-wrap center-content',
		pushLeft && 'push-left'
	);

	const sectionHeader = {
		title: 'Our DNA',
		paragraph: 'is why our clients love engaging with us'
	};

	return (
		<section {...props} className={outerClasses}>
			<div className='container'>
				<div className={innerClasses}>
					<SectionHeader
						style={{ paddingTop: '60px' }}
						data={sectionHeader}
						className='center-content'
					/>
					<div className={tilesClasses}>
						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='features-tiles-item-image mb-16'>
										<Image
											src={require('../../assets/images/trust.svg')}
											alt='Features tile icon 01'
											width={70}
											height={70}
										/>
									</div>
								</div>
								<div className='features-tiles-item-content'>
									<h4 className='mt-0 mb-8'>Trust</h4>
									<p className='m-0 text-sm'>
										Trust – it is in our DNA.. forms the basis of all our
										interactions with our stakeholders.
									</p>
								</div>
							</div>
						</div>

						<div
							className='tiles-item reveal-from-bottom'
							data-reveal-delay='200'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='features-tiles-item-image mb-16'>
										<Image
											src={require('../../assets/images/icons-02.png')}
											alt='Features tile icon 02'
											width={70}
											height={70}
										/>
									</div>
								</div>
								<div className='features-tiles-item-content'>
									<h4 className='mt-0 mb-8'>Passionate Workforce</h4>
									<p className='m-0 text-sm'>
										{/* Young workforce… a.k.a TBians.. an environment of
										innovation, dynamism, flexibility and learning */}
										A passionate team within an environment of innovation,
										dynamism, flexibility and learning.
									</p>
								</div>
							</div>
						</div>

						<div
							className='tiles-item reveal-from-bottom'
							data-reveal-delay='400'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='features-tiles-item-image mb-16'>
										<Image
											src={require('../../assets/images/winwin.svg')}
											alt='Features tile icon 03'
											width={70}
											height={70}
										/>
									</div>
								</div>
								<div className='features-tiles-item-content'>
									<h4 className='mt-0 mb-8'>Win-Win</h4>
									<p className='m-0 text-sm'>
										WIN-WIN.. a strong desire to ensure results for all our
										stakeholders without compromise in quality.
									</p>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='features-tiles-item-image mb-16'>
										<Image
											src={require('../../assets/images/6sigma.svg')}
											alt='Features tile icon 04'
											width={70}
											height={70}
										/>
									</div>
								</div>
								<div className='features-tiles-item-content'>
									<h4 className='mt-0 mb-8'>Lean Principles</h4>
									<p className='m-0 text-sm'>
										{/* Lean Principles.. we strive to avoid waste for us and our
										clients */}
										Deliver value from the customer’s perspective, eliminate
										things that deter value of the services.
									</p>
								</div>
							</div>
						</div>

						<div
							className='tiles-item reveal-from-bottom'
							data-reveal-delay='200'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='features-tiles-item-image mb-16'>
										<Image
											src={require('../../assets/images/gear.svg')}
											alt='Features tile icon 05'
											width={70}
											height={70}
										/>
									</div>
								</div>
								<div className='features-tiles-item-content'>
									<h4 className='mt-0 mb-8'>Continuous Learning</h4>
									<p className='m-0 text-sm'>
										Continuously Learning.. we never claim the peak as we set
										high benchmarks for ourselves.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
