import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Button from '../elements/Button';
import SmoothScroll from '../elements/SmoothScroll';

const propTypes = {
	...SectionSplitProps.types
};

const defaultProps = {
	...SectionSplitProps.defaults
};

const FeaturesSplit = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	invertMobile,
	invertDesktop,
	alignTop,
	imageFill,
	...props
}) => {
	const outerClasses = classNames(
		'features-split section',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'features-split-inner section-inner',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const splitClasses = classNames(
		'split-wrap',
		invertMobile && 'invert-mobile',
		invertDesktop && 'invert-desktop',
		alignTop && 'align-top'
	);

	const sectionHeader = {
		title: 'Services we offer',
		paragraph: ''
	};

	return (
		<section {...props} className={outerClasses}>
			<div id='our-services' className='container'>
				<div className={innerClasses}>
					<SectionHeader
						style={{ paddingBottom: '40px' }}
						data={sectionHeader}
						className='center-content'
					/>
					<div className={splitClasses}>
						<div className='split-item' style={{ paddingBottom: '20px' }}>
							<div
								className='split-item-content center-content-mobile reveal-from-left'
								data-reveal-container='.split-item'>
								<h5 className='mt-0 mb-32'>Full-Stack Development</h5>
								<ul>
									<li>
										<p>
											We have an excellent team of full-stack developers who are
											capable of creating engaging and high-performance websites
											and applications.
										</p>
									</li>
									<li>
										<p>
											Combining our developer’s skills & experience with latest
											technologies we can deliver you robust applications.
										</p>
									</li>
									<SmoothScroll to='fullstack' duration={400}>
										<Button
											tag='input'
											style={{
												outline: 'none',
												backgroundColor: '#A8CF45',
												borderRadius: '24px',
												width: '150px',
												height: '46px'
											}}
											type='submit'
											value='Learn more'
										/>
									</SmoothScroll>
								</ul>
							</div>
							<div
								className={classNames(
									'split-item-image center-content-mobile reveal-from-bottom',
									imageFill && 'split-item-image-fill'
								)}
								data-reveal-container='.split-item'>
								<Image
									src={require('./../../assets/images/UI-images-03.svg')}
									alt='Features split 01'
									width={450}
									height={300}
								/>
							</div>
						</div>

						<div className='split-item' style={{ paddingTop: '20px' }}>
							<div
								className='split-item-content center-content-mobile reveal-from-right'
								data-reveal-container='.split-item'>
								<h5 className='mt-0 mb-32'>Digital Marketing</h5>

								<ul>
									<li>
										<p>
											We help companies effortlessly showcase their products &
											services to specifically targeted user groups.
										</p>
									</li>
									<li>
										<p>
											Through our digital marketing services, we will bring
											together technology and the art of communication to enrich
											your digital presence.
										</p>
									</li>
									{/* <SmoothScroll to='contact-form' duration={400}>
										<Button
											tag='input'
											style={{
												outline: 'none',
												backgroundColor: '#A8CF45',
												borderRadius: '24px',
												width: '120px',
												height: '43px'
											}}
											type='submit'
											value='Learn more'
										/>
									</SmoothScroll> */}
								</ul>
							</div>
							<div
								className={classNames(
									'split-item-image center-content-mobile reveal-from-bottom',
									imageFill && 'split-item-image-fill'
								)}
								data-reveal-container='.split-item'>
								<Image
									src={require('./../../assets/images/UI-images-04.svg')}
									alt='Features split 02'
									width={450}
									height={300}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
