import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from '../elements/Button';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import SmoothScroll from '../elements/SmoothScroll';

const propTypes = {
	...SectionProps.types,
	split: PropTypes.bool
};

const defaultProps = {
	...SectionProps.defaults,
	split: false
};

const Cta = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	split,
	...props
}) => {
	const [formdata, setformdata] = useState({
		fullname: '',
		email: '',
		subject: '',
		message: '',
		sent: false
	});
	const { fullname, email, subject, message, sent } = formdata;

	const onChange = e => {
		setformdata({ ...formdata, [e.target.name]: e.target.value });
	};
	const [verification, setverified] = useState({
		verified: false
	});
	const { verified } = verification;

	const recaptcha = () => {
		setverified({ verified: true });
	};

	const onSubmit = e => {
		e.preventDefault();
		console.log('after preventdefault');

		if (verified) {
			console.log('recaptcha verified, calling mail function in the next line');
			mail({ fullname, email, subject, message });
			const timer = setTimeout(() => {
				setformdata({ sent: true });
			}, 1);
			return () => clearTimeout(timer);
		} else {
			alert('Please verify that you are not a robot');
		}
	};

	const mail = async ({ fullname, email, subject, message }) => {
		const config = {
			headers: {
				'content-Type': 'application/json'
			}
		};
		try {
			const body = JSON.stringify({ fullname, email, subject, message });
			console.log(body);
			await axios.post('/api/form', body, config);
			resetForm();
		} catch (err) {
			console.log(err);
		}
	};

	const resetForm = () => {
		setformdata({
			fullname: '',
			email: '',
			subject: '',
			message: ''
		});
	};

	const outerClasses = classNames(
		'cta section center-content-mobile reveal-from-bottom',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'cta-inner section-inner',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider',
		split && 'cta-split'
	);

	return (
		<section {...props} id='contact-form' className={outerClasses}>
			<div className='container'>
				<div style={{ paddingTop: '20px' }} className={innerClasses}>
					<div className='cta-slogan'>
						<h4 className='mb-32' style={{ color: '#006CB5' }}>
							Contact Us
						</h4>
						<div className=' mr-12'>
							<p className='text-xs' style={{textAlign: 'justify', marginLeft: '4%'}}>
								{/* <i
									className='fas fa-map-marker-alt mx-2'
									style={{ color: '#2f3037', marginRight: '20px'}}></i> */}
								Trusty Bytes Pvt Ltd, Third Floor, Smartworks Bharati Vilas, 100 Feet Rd, Guindy Industrial Estate, 26B, Ekkatuthangal, Chennai, Tamil Nadu 600032
							</p>
							<p className='text-xs' style={{textAlign: 'justify', marginLeft: '4%'}}>
								{/* <i
									className='fas fa-envelope mx-2'
									style={{ color: '#2f3037', marginRight: '16px' }}></i> */}
								info@trustybytes.com
							</p>
							<p className='text-xs' style={{textAlign: 'justify', marginLeft: '4%'}}>
								{/* <i
									className='fas fa-phone-alt mx-2'
									style={{
										color: '#2f3037',
										marginRight: '16px'
									}}></i> */}
								+91 755 000 3114
							</p>
							<p className='text-xl' style={{textAlign: 'justify', marginLeft: '4%'}}>
								{/* <i
									className='fab fa-whatsapp mx-2'
									style={{
										color: '#2f3037',
										marginRight: '20px',
										marginBottom: '24px'
									}}></i> */}
								<a
									href='https://wa.me/917550003114'
									className='text-xs'
									target='_blank'
									rel='noopener noreferrer'
									style={{
										color: '#2f3037'
									}}>
									Whatsapp
								</a>
								{/* +91 755 000 3114 */}
							</p>
							<iframe
								title='google-maps'
								src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7336.804940837686!2d80.26612803800226!3d13.065107801480229!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x206f8c26155aeb36!2sTrusty%20Bytes!5e0!3m2!1sen!2sin!4v1617956771003!5m2!1sen!2sin'
								width='310'
								height='315'
								style={{
									border: 'solid 2px #2f3037',
									borderColor: '#2f3037',
									borderRadius: '10px'
								}}
								loading='lazy'></iframe>
						</div>
					</div>
					<div className='cta-action'>
						<form
							className='pr-12'
							style={{ paddingTop: '45px' }}
							onSubmit={e => onSubmit(e)}>
							<h4 style={{ color: '#006CB5', marginLeft: '17px' }}>
								Write to us <br />
								<p
									style={{
										marginLeft: '5px',
										fontSize: '16px',
										fontWeight: 'normal'
									}}>
									Feel free to drop us a line below
								</p>
							</h4>

							<fieldset className='pr-32 pl-32'>
								<div className='mb-12 ' style={{ minWidth: '220px' }}>
									<label className='text-xs' style={{ color: '#2f3037' }}>
										Full name
									</label>
									<Input
										type='text'
										name='fullname'
										value={fullname}
										onChange={e => onChange(e)}
										required
									/>
								</div>
								<div className='mb-12'>
									<label className='text-xs' style={{ color: '#2f3037' }}>
										Email
									</label>
									<Input
										type='email'
										name='email'
										value={email}
										onChange={e => onChange(e)}
										required
									/>
								</div>
								<div className='mb-12'>
									<label className='text-xs' style={{ color: '#2f3037' }}>
										Subject
									</label>
									<Input
										type='text'
										name='subject'
										value={subject}
										onChange={e => onChange(e)}
										required
									/>
								</div>
								<div className='mb-12'>
									<label className='text-xs' style={{ color: '#2f3037' }}>
										Message
									</label>
									<Input
										type='textarea'
										name='message'
										value={message}
										onChange={e => onChange(e)}
										placeholder='Let us know what you think'
										rows={4}
									/>
								</div>
								{sent ? (
									<p style={{ fontSize: '20px', color: '#006CB5' }}>
										We got your message, Will get back to you ASAP
									</p>
								) : (
									<p></p>
								)}
								<div className='mt-12 row'>
									<div className='col-md-4'>
										<Button
											className='mt-12'
											tag='input'
											style={{
												outline: 'none',
												backgroundColor: '#A8CF45',
												borderRadius: '24px'
											}}
											type='submit'
											value={'submit'}
										/>
									</div>
									<div className='col-md-8'>
										<ReCAPTCHA
											size='normal'
											theme='light'
											sitekey='6LeniqkaAAAAAEdwnDgG1Zly_BGt2s65IGJjzrxV'
											onChange={recaptcha}
										/>
									</div>
								</div>
							</fieldset>
						</form>
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<SmoothScroll to='header' duration={600}>
					<Button
						style={{
							outline: 'none',
							backgroundColor: 'transparent',
							color: '#2f3037'
						}}>
						Back to top <i className='fas fa-arrow-up'></i>
					</Button>
				</SmoothScroll>
			</div>
		</section>
	);
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
