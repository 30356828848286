import React from 'react';
import classNames from 'classnames';
import SmoothScroll from '../../elements/SmoothScroll';
import { Link } from 'react-router-dom';

const FooterNav = ({ className, ...props }) => {
	const classes = classNames('footer-nav', className);

	return (
		<nav {...props} className={classes}>
			<ul className="list-reset">
				<li>
					<SmoothScroll to="header" duration={500}>
						Home
					</SmoothScroll>
				</li>
				<li>
					<SmoothScroll to="carousal" duration={100}>
						About Us
					</SmoothScroll>
				</li>
				<li>
					<SmoothScroll to="contact-form" duration={10}>
						Contact Us
					</SmoothScroll>
				</li>
				<li>
					<Link to="/terms-of-service">Terms Of Service </Link>{' '}
				</li>
				<li>
					<Link to="/privacy-policy">Privacy policy</Link>
				</li>
			</ul>
		</nav>
	);
};

export default FooterNav;
