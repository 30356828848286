import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Cta from '../components/sections/Cta';
import Fullstack from '../components/sections/Fullstack';
import Carousal from '../components/sections/Carousal';

const Home = () => {
	return (
		<>
			<Hero className='illustration-section-01' />
			<FeaturesTiles hasBgColor />
			<FeaturesSplit
				invertMobile
				imageFill
				className='illustration-section-02'
			/>
			<Fullstack hasBgColor />
			{/* <Testimonial topDivider bottomDivider /> */}
			<Carousal invertDesktop />

			<Cta split hasBgColor />
		</>
	);
};

export default Home;
