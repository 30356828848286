import React from 'react';
import classNames from 'classnames';

const FooterSocial = ({ className, ...props }) => {
	const classes = classNames('footer-social', className);

	return (
		<div {...props} className={classes}>
			<ul className='list-reset'>
				<li>
					<a
						className='hover-item'
						href='https://facebook.com/'
						target='_blank'
						rel='noopener noreferrer'>
						<i
							className='fab fa-facebook-f fa-lg'
							// style={{ color: '#006CB5' }}
						></i>
					</a>
				</li>
				<li>
					<a
						className='hover-item'
						href='https://twitter.com/'
						target='_blank'
						rel='noopener noreferrer'>
						<i
							className='fab fa-twitter fa-lg '
							// style={{ color: '#006CB5' }}
						></i>
					</a>
				</li>
				<li>
					<a
						className='hover-item'
						href='https://www.instagram.com/'
						target='_blank'
						rel='noopener noreferrer'>
						<i
							className='fab fa-instagram fa-lg'
							// style={{ color: '#006CB5' }}
						></i>
					</a>
				</li>
				<li>
					{' '}
					<a
						className='hover-item'
						href='https://www.linkedin.com/company/trusty-bytes'
						target='_blank'
						rel='noopener noreferrer'>
						<i
							className='fab fa-linkedin-in fa-lg '
							// style={{ color: '#006CB5' }}
						></i>
					</a>
				</li>
			</ul>
		</div>
	);
};

export default FooterSocial;
