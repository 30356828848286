import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';
import { Link } from 'react-router-dom';
import Logo from './partials/Logo';
import PropTypes from 'prop-types';
import React from 'react';
import SmoothScroll from '../elements/SmoothScroll';
import classNames from 'classnames';
import currentYear from '../../utils/common'

const propTypes = {
	topOuterDivider: PropTypes.bool,
	topDivider: PropTypes.bool,
};

const defaultProps = {
	topOuterDivider: false,
	topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
	const classes = classNames(
		'site-footer center-content-mobile',
		topOuterDivider && 'has-top-divider',
		className
	);

	return (
		<footer {...props} className={classes}>
			<div className="container">
				<div
					id="fooo"
					className={classNames(
						'site-footer-inner',
						topDivider && 'has-top-divider'
					)}
				>
					<div className="footer-top space-between text-xxs">
						<SmoothScroll to="header" duration={500}>
							<Logo />
						</SmoothScroll>
						<FooterSocial />
					</div>
					<div className="footer-bottom space-between text-xxs invert-order-desktop">
						<FooterNav />
						<div className="footer-copyright">
							{' '}
							<a href="https://trustybytes.com">Trusty Bytes</a>. All right
							reserved {currentYear}
							{/* <div>
								<Link to="/terms-of-service">Terms Of Service </Link>
								<>{'   .  '}</>
								<Link to="/privacy-policy"> Privacy Policy</Link>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
