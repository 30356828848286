import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import SmoothScroll from '../elements/SmoothScroll';

const propTypes = {
	...SectionProps.types
};

const defaultProps = {
	...SectionProps.defaults
};

const Hero = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	...props
}) => {
	const outerClasses = classNames(
		'hero section center-content',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	// const innerClasses = classNames(
	// 	'hero-inner section-inner',
	// 	topDivider && 'has-top-divider',
	// 	bottomDivider && 'has-bottom-divider'
	// );

	return (
		<section {...props} id='header' className={outerClasses}>
			<div className='container'>
				<div
					className='split-wrap '
					style={{ marginTop: '65px', marginBottom: '75px' }}>
					<div className='split-item'>
						<div
							className='split-item-content center-content-mobile reveal-from-left is-revealed'
							data-reveal-container='.split-item'>
							<div
								className='text-lg   fw-600 tt-u'
								style={{
									color: '#006CB5',
									marginBottom: '30px',
									textAlign: 'left'
								}}>
								Full Stack Development services
							</div>

							<p
								className='m-0'
								style={{
									textAlign: 'left'
								}}>
								Leverage our Full stack Developers for your software development
								projects. Flexible engagement models focused on your success.
								<br />
								<br />
								<SmoothScroll to='fullstack' duration={400}>
									<Button
										tag='input'
										style={{
											outline: 'none',
											backgroundColor: '#A8CF45',
											borderRadius: '24px',
											width: '150px',
											height: '46px'
										}}
										type='submit'
										value='Learn more'
									/>
								</SmoothScroll>
							</p>
						</div>
						<div
							className='split-item-image center-content-mobile reveal-from-bottom split-item-image-fill is-revealed'
							data-reveal-container='.split-item'>
							<Image
								src={require('../../assets/images/homepage-image.svg')}
								alt='Open'
								width={528}
								height={396}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
