import React from 'react';
import ScrollToTop from '../utils/ScrollToTop';
import SectionHeader from '../components/sections/partials/SectionHeader';
import { SectionSplitProps } from '../utils/SectionProps';
import classNames from 'classnames';
import currentYear from '../utils/common'

const propTypes = {
	...SectionSplitProps.types,
};

const defaultProps = {
	...SectionSplitProps.defaults,
};

const PrivacyPolicy = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	invertMobile,
	invertDesktop,
	alignTop,
	imageFill,
	...props
}) => {
	const outerClasses = classNames(
		'features-split section',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'features-split-inner section-inner',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const sectionHeader = {
		title: 'Privacy Policy',
		paragraph: `Last updated on January 1, ${currentYear}`,
	};

	return (
		<section {...props} className={outerClasses}>
			<ScrollToTop />
			<div className="container">
				<div id="fullstack" className={innerClasses}>
					<SectionHeader
						style={{ paddingBottom: '40px' }}
						data={sectionHeader}
						id="terms"
						className="center-content"
					/>
					<p>
						Your Privacy is vital to us, Trusty Bytes Private Limited and its
						affiliates (“TrustyBytes” or “We”) respects your Privacy and is
						committed to protecting it through compliance to this Privacy
						Policy.
					</p>
					<br />
					<p>
						When you communicate to us through phone calls/email’s/other mode of
						communication (including when you use our Website) we may request
						for your Personal Data to get valuable feedback from your experience
						and we shall provide you the best of services relying on
						TrustyBytes’s core value of Trust. Trust is our identity. Our value
						is to protect the privacy and Personal Data of every user who
						discloses Personal Data to TrustyBytes. We are committed to
						protecting the Privacy of our clients, prospective clients,
						contractors, consultants, employees and business partners.
					</p>
					<br />
					<p>
						We recommend that you read this Privacy Policy in full to ensure you
						are fully informed. If you have any questions about this Privacy
						Policy or about TrustyBytes’s data collection, use, and disclosure
						practices, please contact us at{' '}
						<a href="#!" style={{ color: 'blue' }}>
							info@TrustyBytes.com
						</a>
						.
					</p>
					<br />
					<p>
						We may supplement this Privacy Notice to address specific
						situations. All supplemental notices should be read together with
						this Privacy Notice.
					</p>
					<h5 className="mt-0 mb-32">Data we Collect</h5>
					<p>
						When you access our Website and communicate to us through phone
						calls/email’s/other mode of communication we might ask for your
						Personal Data, contact information, including items such as first
						name, last name, date of birth, address, phone number (personal or
						official), email address, nationality, profession, languages,
						passport details, ID card, social security numb er or other national
						ID card, driver’s license details, personal information about
						spouse, parents, children, gender, religion, marital status, wage,
						salary, work permit, insurances, bank account, credit card details,
						educational details, work experience, electronic identification
						data, picture, images, photographs and also ask you to leave a
						message through which we may receive certain additional information
						which is included in the contents of the message and/or attachments
						you may send us and any other information you may choose to provide.
					</p>
					<br />
					<p>
						We sometimes collect anonymous information from visits to the Sites.
						Anonymous information (or click stream data) refers to information
						that does not and will not directly or indirectly reveal your
						identity, e.g., aggregated data relating to all of our website
						visitors. We may use this data to analyze trends and statistics and
						to help us provide you with a better experience using our Sites.
					</p>
					<br />
					<p>
						‘Personal Data’ means any information relating to an identified or
						identifiable natural person (“data subject” or “you” or “your”); an
						identifiable natural person is one who can be identified, directly
						or indirectly, in particular by reference to an identifier such as a
						name, an identification number, location data, an online identifier
						or to one or more factors specific to the physical, physiological,
						genetic, mental, economic, cultural or social identity of that
						natural person.
					</p>
					<br />
					<p>
						In connection with the services provided by TrustyBytes, TrustyBytes
						may receive, store and/or process Personal Data on behalf of third
						parties including Clients, Business Partners, Vendors, Contractors,
						Consultants, Service Providers and advisors. In such cases,
						TrustyBytes will process such Personal Data pursuant to the terms of
						any contracts with such third parties.
					</p>
					<br />
					<p>
						If you provide us with personal information of third parties (such
						as the details of your administrative assistant or your emergency
						contact), you agree to: (a) notify each individual before sharing
						their personal information with us, (b) explain that their personal
						information will be processed in accordance with this Privacy
						Notice, and (c) obtain their consent, where appropriate.
					</p>
					<br />
					<p>
						In addition, Personal Data may be collected by TrustyBytes, its
						employees, consultants or contractors (“TrustyBytes Personnel”) (I)
						as a result of your status as an employee, consultant or contractor
						of TrustyBytes, or (II) when you (a) contact TrustyBytes through its
						website, (b) register for or interact with TrustyBytes Personnel at
						an event, (c) complete a survey from TrustyBytes or TrustyBytes
						Personnel, (d) correspond with TrustyBytes or TrustyBytes Personnel,
						(e) inquire about TrustyBytes’s service offerings, or (f) inquire
						about or apply to become an employee, consultant or contractor of
						TrustyBytes. Personal Data is also collected through marketing,
						recruitment, sales and other commercial activities. Personal Data is
						provided by you voluntarily on the basis of legitimate interest
						pursued by TrustyBytes and data subject or by a third party, except
						where such interests are overridden by the interests or fundamental
						rights and freedoms of the data subject which require protection of
						Personal Data. By submitting your Personal Data you consent to its
						use in a manner consistent with this Privacy Policy.
					</p>

					<h5 className="mt-0 mb-32">Social Network</h5>
					<p>
						TrustyBytes has social network pages to post information related to
						our company or promote the services offered by TrustyBytes and to
						allow data subject to comment or show interest in our services. We
						may use your Personal Data on the basis of legitimate interest,
						except where such interests are overridden by the interests or
						fundamental rights and freedoms of the data subject which require
						protection of Personal Data. You can also refer to the Privacy
						Policy of such social networking websites to understand how the
						Personal Data is dealt by respective social networking entities.
					</p>
					<br />
					<p>
						Sensitive Personal Data is generally only collected from TrustyBytes
						Personnel as (a) required by law or (b) for employment purposes and
						will be used strictly for such purposes
					</p>
					<h5 className="mt-0 mb-32">How we use the Data</h5>
					<p>
						We use your Personal Data to perform the contract with you, provide
						you with customer support and reply to your requests made through
						the contact form, find and prevent fraud, develop new content,
						features and functionality, collect and manage your consents, record
						your requests and the communication between you and TrustyBytes
						regarding your Personal Data. TrustyBytes reserves the right to use
						Personal Data collected for any legitimate business purpose for: (i)
						providing you the services you requested, (ii) communicating
						information about products or services, (iii) offering opportunities
						to become a TrustyBytes Personnel, (iv) responding to requests, (v)
						tracking compliance with the Terms and Conditions of Use of
						TrustyBytes’s website, (vi) analyzing the characteristics of
						visitors to our website, including applicants and
						clients/prospective clients, and (vii) improving the services we
						offer.
					</p>
					<br />
					<p>Lawful basis for processing your Personal Data –</p>
					<br />
					<p>We process your Personal Data only if:</p>
					<br />
					<ul>
						<li>
							<p>
								the data subject has given consent to the processing of his or
								her personal data for one or more specific purposes;
							</p>
						</li>
						<li>
							<p>
								processing is necessary for the performance of a contract to
								which the data subject is party or in order to take steps at the
								request of the data subject prior to entering into a contract;
							</p>
						</li>
						<li>
							<p>
								processing is necessary for compliance with a legal obligation
								to which TrustyBytes is subject;
							</p>
						</li>
						<li>
							<p>
								processing is necessary in order to protect the vital interests
								of the data subject or of another natural person;
							</p>
						</li>
						<li>
							<p>
								processing is necessary for the performance of a task carried
								out in the public interest
							</p>
						</li>
						<li>
							<p>
								processing is necessary for the purposes of the legitimate
								interests pursued by TrustyBytes or by a third party, except
								where such interests are overridden by the interests or
								fundamental rights and freedoms of the data subject which
								require protection of personal data, in particular where the
								data subject is a child.
							</p>
						</li>
					</ul>
					<br />
					<p>We may share your Personal Data with:</p>
					<br />
					<ul>
						<li>
							<p>
								Employees of TrustyBytes who have a need to know such Personal
								Data.
							</p>
						</li>
						<li>
							<p>
								Third Parties having a binding agreement with us with a
								contracted task and for TrustyBytes’s business purpose
								(including background verification, processing benefits/payroll,
								travel and immigration purposes, operational reasons such as
								booking of flight, hotel room or insurance coverage).
							</p>
						</li>
						<li>
							<p>As required by law.</p>
						</li>
						<li>
							<p>
								Law enforcement, government officials, or other third parties
								pursuant to a subpoena, court order, or other legal proces
							</p>
						</li>
					</ul>
					<h5 className="mt-0 mb-32">Cookies</h5>
					<p>
						We only use essential cookies that help us provide you with the best
						of services. The data we collect includes internet protocol (IP)
						addresses, browser type, web pages, operating system, date/time
						stamp, managing your consents and request for company data.
					</p>
					<br />
					<p>
						Cookies contain a small amount of information that allows our web
						servers to recognize you. We store information that we collect
						through cookies. You can configure your browser to refuse to accept
						cookies in principle or to inform you in advance when a cookie is
						stored. In this case, however, the functionality of the website may
						be compromised, and you may not be able to access our Website in the
						most efficient manner.
					</p>
					<br />
					<p>
						<a style={{ fontWeight: 700 }}>Third-party cookies.</a> We are not
						responsible for any third-party links/cookies in our Website, these
						services might set up their own cookies to remember you. In such
						event kindly read through the Cookies policy of the respective third
						party to understand how your Personal Data is managed.
					</p>
					<h5 className="mt-0 mb-32">Links and Third-Party Processors</h5>
					<p>
						We are committed to reducing to a minimum the Third-Party Processors
						that we use to lower the risks of your Personal Data from being
						exposed. They are carefully selected and regularly reviewed by us to
						ensure that your Personal Data and Privacy is maintained. They are
						bound by the data protection clauses referred in the written
						Agreement with us and are accordingly obligated to treat your
						Personal Data exclusively in accordance with our instructions and
						the applicable data protection laws.
					</p>
					<br />
					<p>
						The Sites contain links to other websites, including sites
						controlled by third parties independent of us. TrustyBytes is not
						responsible for the privacy practices or the contents of such
						websites. We encourage you to read the privacy notices of such
						websites before providing any personal information to them. We do
						not take responsibility for the opinions of third parties expressed
						on or through our Sites.
					</p>
					<h5 className="mt-0 mb-32">Security</h5>
					<p>
						TrustyBytes follows generally accepted industry standards to protect
						the Personal Data, both during transmission and once received by
						TrustyBytes. However, we would like to let you know that no method
						of transmission over the Internet or method of electronic storage is
						100% secure.
					</p>
					<h5 className="mt-0 mb-32">Data Retention</h5>
					<p>
						We retain Personal Data we collect from you where we have an
						existing legally binding agreement/consent from you to avail/provide
						services from/to you.
					</p>
					<br />
					<p>
						When we have no legally binding agreement/consent from you, we will
						either delete or, if this is not possible (for example, because your
						Personal Data has been stored in backup archives), then we will
						securely store your Personal Data and isolate it from any further
						processing until deletion is possible. Provided However, in the
						event it is legally required we retain the Personal Data so long as
						it is necessary for the purpose for which it was collected.
					</p>
					<br />
					<p>
						Further, to protect your privacy and security, we also take
						reasonable steps to verify your identity before updating or removing
						your Personal Data. The Personal Data you provide to us may be
						archived or stored periodically by us according to backup processes
						conducted in the ordinary course of business for disaster recovery
						purposes.
					</p>
					<br />
					<p>
						Your personal information will be retained only for so long as
						reasonably necessary for the purposes set out in this Privacy Notice
						in accordance with applicable laws, including for the purposes of
						satisfying any legal, regulatory, accounting or reporting
						requirements.
					</p>
					<h5 className="mt-0 mb-32">
						Principles for processing your Personal Data
					</h5>
					<p>Your Personal data shall be:</p>
					<br />
					<ul>
						<li>
							<p>
								processed lawfully, fairly and in a transparent manner in
								relation to the data subject (‘lawfulness, fairness and
								transparency’);
							</p>
						</li>
						<li>
							<p>
								collected for specified, explicit and legitimate purposes and
								not further processed in a manner that is incompatible with
								those purposes; further processing for archiving purposes in the
								public interest, scientific or historical research purposes or
								statistical purposes (‘purpose limitation’);
							</p>
						</li>
						<li>
							<p>
								adequate, relevant and limited to what is necessary in relation
								to the purposes for which they are processed (‘data
								minimisation’);
							</p>
						</li>
						<li>
							<p>
								accurate and, where necessary, kept up to date; TrustyBytes take
								every reasonable step to ensure that personal data that are
								inaccurate, having regard to the purposes for which they are
								processed, are erased or rectified without delay (‘accuracy’);
							</p>
						</li>
						<li>
							<p>
								kept in a form which permits identification of data subjects for
								no longer than is necessary for the purposes for which the
								personal data are processed; personal data may be stored for
								longer periods insofar as the personal data will be processed
								solely for archiving purposes in the public interest, scientific
								or historical research purposes or statistical purposes
								(‘storage limitation’);
							</p>
						</li>
						<li>
							<p>
								processed in a manner that ensures appropriate security of the
								personal data, including protection against unauthorised or
								unlawful processing and against accidental loss, destruction or
								damage, using appropriate technical or organisational measures
								(‘integrity and confidentiality’).
							</p>
						</li>
						<li>
							<p>
								TrustyBytes shall be responsible for, and be able to demonstrate
								compliance with this Privacy Policy (accountability).
							</p>
						</li>
					</ul>
					<br />
					<h5 className="mt-0 mb-32">Your Data Protection Rights</h5>
					<ul>
						<li>
							<p>
								<a style={{ fontWeight: 700 }}>Right of Access.</a> You have the
								right to obtain from us confirmation as to whether or not
								Personal Data concerning you are processed, and, where that is
								the case, you have the right to request and get access to that
								Personal Data.
							</p>
						</li>
						<li>
							<p>
								<a style={{ fontWeight: 700 }}>Right to Rectification.</a> You
								have the right to obtain from us the rectification of inaccurate
								Personal Data and you have the right to provide additional
								Personal Data to complete any incomplete Personal Data.
							</p>
						</li>
						<li>
							<p>
								<a style={{ fontWeight: 700 }}>Right to Erasure.</a> You have
								the right to obtain from us the erasure of your Personal Data.
							</p>
						</li>
						<li>
							<p>
								<a style={{ fontWeight: 700 }}>
									Right to Restriction of Processing.
								</a>{' '}
								You have the right to obtain from us restriction of processing,
								applicable for a certain period and/or for certain situations.
							</p>
						</li>
						<li>
							<p>
								<a style={{ fontWeight: 700 }}>Right to Object.</a> You have the
								right to object to processing of your Personal Data. You have
								the right to object at further processing of your Personal Data
								in so far as such information have been collected for direct
								marketing purposes.
							</p>
						</li>
						<li>
							<p>
								<a style={{ fontWeight: 700 }}>
									Right to be Not Subject to Automated Individual
									Decision-Making.
								</a>
								You have the right to not be subject to a decision based solely
								on automated processing.
							</p>
						</li>
						<li>
							<p>
								<a style={{ fontWeight: 700 }}>Right to Filing Complaints.</a>{' '}
								You have the right to file complaints with the applicable data
								protection authority on our processing of your Personal Data.
							</p>
						</li>
					</ul>
					<br />
					<p>
						You have a right for the aforementioned reasons for usage of your
						Personal Data that you previously provided to TrustyBytes by sending
						such request to info@TrustyBytes.com. TrustyBytes will comply with
						such requests unless TrustyBytes has a legitimate business purpose
						for continuing to use such Personal Data, such as maintaining
						employment records.
					</p>
					<h5 className="mt-0 mb-32">Children</h5>
					<p>
						TrustyBytes’s website is not intended for use by children.
						TrustyBytes does not knowingly solicit or collect Personal Data from
						children under the age of 18. If you are under the age of 18, you
						must obtain the consent of your parent or guardian to use
						TrustyBytes’s website.
					</p>
					<h5 className="mt-0 mb-32">Contact Us</h5>
					<p>
						Any questions, concerns or complaints about our processing
						operations and our commitment to this Privacy Notice should be
						addressed to TrustyBytes Data Protection Officer at
						info@trustybytes.com.{' '}
					</p>
					<br />
					<p>
						<a style={{ fontWeight: 700 }}>You can also write to us at:</a>
					</p>
					<a style={{ color: 'black' }}>Trusty Bytes Private Limited</a>
					<br />
					<a style={{ color: 'black' }}>Attention: Data Protection Officer</a>
					<br />
					<a style={{ color: 'black' }}>
						{' '}
						813, Nizara Bonanza 6th Floor, Anna Salai, Chennai TN 600002, India
					</a>
					<br />
					<br />
					<h5 className="mt-0 mb-32">Changes to this Privacy Policy</h5>
					<p>
						TrustyBytes reserves the right to modify or amend this Privacy
						Policy at any time and for any reason. Any changes to this Privacy
						Policy posted on the Website apply as soon as they are posted. By
						continuing to use the Website after any changes are posted, user
						acknowledges acceptance of those changes.
					</p>
				</div>
			</div>
		</section>
	);
};
PrivacyPolicy.propTypes = propTypes;
PrivacyPolicy.defaultProps = defaultProps;

export default PrivacyPolicy;
