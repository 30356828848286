import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Button from '../elements/Button';
import SmoothScroll from '../elements/SmoothScroll';

const propTypes = {
	...SectionSplitProps.types
};

const defaultProps = {
	...SectionSplitProps.defaults
};

const Fullstack = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	invertMobile,
	invertDesktop,
	alignTop,
	imageFill,
	...props
}) => {
	const outerClasses = classNames(
		'features-split section',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'features-split-inner section-inner',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const splitClasses = classNames(
		'split-wrap',
		invertMobile && 'invert-mobile',
		invertDesktop && 'invert-desktop',
		alignTop && 'align-top'
	);

	const sectionHeader = {
		title: 'Full Stack Development',
		paragraph:
			'Full stack development engineers across various technologies like MERN, MEAN, Java & DotNet'
	};

	return (
		<section {...props} className={outerClasses}>
			<div className='container'>
				<div id='fullstack' className={innerClasses}>
					<SectionHeader
						style={{ paddingBottom: '40px' }}
						data={sectionHeader}
						id='fullstack'
						className='center-content'
					/>
					<div className={splitClasses}>
						<div className='split-item'>
							<div
								className='split-item-content center-content-mobile reveal-from-left'
								data-reveal-container='.split-item'>
								<h5 className='mt-0 mb-32'>Full-Stack Digital DevOps</h5>

								<ul>
									<li>
										<p>Development and Operations</p>
									</li>
									<li>
										<p>Front End, Back End</p>
									</li>
									<li>
										<p>CI/CD</p>
									</li>
									<li>
										<p>Automation and Testing</p>
									</li>
									<li>
										<p>Environments</p>
									</li>
									<li>
										<p>Adjacent Skills</p>
									</li>
									<li>
										<p>Latest trends</p>
									</li>
									<SmoothScroll to='contact-form' duration={400}>
										<Button
											tag='input'
											style={{
												outline: 'none',
												backgroundColor: '#A8CF45',
												borderRadius: '24px',
												width: '155px',
												height: '46px'
											}}
											type='submit'
											value='Get in touch'
										/>
									</SmoothScroll>
								</ul>
							</div>
							<div
								className={classNames(
									'split-item-image center-content-mobile reveal-from-bottom',
									imageFill && 'split-item-image-fill'
								)}
								data-reveal-container='.split-item'>
								<Image
									src={require('./../../assets/images/UI-images-table-02.svg')}
									alt='Features split 01'
									width={600}
									height={400}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

Fullstack.propTypes = propTypes;
Fullstack.defaultProps = defaultProps;

export default Fullstack;
