import React from 'react';
import classNames from 'classnames';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const Carousal = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	invertMobile,
	invertDesktop,
	alignTop,
	imageFill,
	...props
}) => {
	// const outerClasses = classNames(
	// 	'features-split section',
	// 	topOuterDivider && 'has-top-divider',
	// 	bottomOuterDivider && 'has-bottom-divider',
	// 	hasBgColor && 'has-bg-color',
	// 	invertColor && 'invert-color',
	// 	className
	// );

	const innerClasses = classNames(
		'features-split-inner section-inner',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const splitClasses = classNames(
		'split-wrap',
		invertMobile && 'invert-mobile',
		invertDesktop && 'invert-desktop',
		alignTop && 'align-top'
	);

	const sectionHeader1 = {
		title: 'Why Trusty Bytes',
		paragraph: 'What makes us stand from the rest'
	};
	const sectionHeader2 = {
		title: 'Who we are',
		paragraph:
			'A young and nimble IT Services Company focused on Digital Services'
	};
	const sectionHeader3 = {
		title: 'Blueprint of our vision',
		paragraph: ' '
	};

	return (
		<div className='container' id='carousal'>
			<div
				id='carouselExampleInterval'
				className='carousel carousel-dark slide'
				data-bs-ride='carousel'>
				<div className='carousel-inner'>
					<div className='carousel-item active' data-bs-interval='5000'>
						<div className='container'>
							<div className={innerClasses}>
								<SectionHeader
									data={sectionHeader2}
									className='center-content'
								/>
								<div className={splitClasses}>
									<div className='split-item' style={{ paddingTop: '12px' }}>
										<div
											className='split-item-content center-content-mobile reveal-from-left'
											data-reveal-container='.split-item'>
											<h5 className='mt-0 mb-6'>Founded</h5>

											<p style={{ fontSize: '17px' }}>
												In December 2020 by a team of Industry veterans. Focused
												to build a company that is based on Trust while
												delivering outcomes to clients.
											</p>
											<h5 className='mt-0 mb-6'>Location</h5>
											<p style={{ fontSize: '17px' }}>
												Based out of Chennai, India. Seek to leverage remote
												working and be able to cater to needs of a global client
												base.
											</p>
											<h5 className='mt-0 mb-6'>Digital Services</h5>
											<p style={{ fontSize: '17px' }}>
												Digital Tech: Full-stack, Digital DevOps & Digital
												Marketing.
												<br />
												Digital Domain: Industry Domains such as Life Sciences,
												Retail.
											</p>
										</div>
										<div
											className={classNames(
												'split-item-image center-content-mobile reveal-from-bottom',
												imageFill && 'split-item-image-fill'
											)}
											data-reveal-container='.split-item'>
											<Image
												src={require('./../../assets/images/UI-images-01.svg')}
												alt='Features split 01'
												width={450}
												height={300}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='carousel-item' data-bs-interval='5000'>
						<div className='container-sm'>
							<div id='carousal1' className={innerClasses}>
								<SectionHeader
									data={sectionHeader1}
									className='center-content'
								/>
								<div className={splitClasses}>
									<div className='split-item' style={{ paddingTop: '10px' }}>
										<div
											className='split-item-content center-content-mobile reveal-from-left'
											data-reveal-container='.split-item'>
											<ul>
												<li>
													<p style={{ marginBottom: '50px' }}>
														A trusted Partner committed to your success
													</p>
												</li>
												<li>
													<p style={{ marginBottom: '50px' }}>
														Flexible Engagement Models
														<p style={{ fontSize: '15px' }}>
															- Build Operate Transfer, Staff Aug, Fixed Price,
															Outcome driven
														</p>
													</p>
												</li>
												<li>
													<p style={{ marginBottom: '50px' }}>
														Highly flexible ramp up/ramp downs
													</p>
												</li>
												<li>
													<p style={{ marginBottom: '50px' }}>
														Full stack DevOps engineers
														<p style={{ fontSize: '15px' }}>
															-Improved utilization in your programs
														</p>
													</p>
												</li>
											</ul>
										</div>
										<div
											className={classNames(
												'split-item-image center-content-mobile reveal-from-bottom',
												imageFill && 'split-item-image-fill'
											)}
											data-reveal-container='.split-item'>
											<Image
												style={{ marginBottom: '2.14px' }}
												src={require('./../../assets/images/UI-images-02.svg')}
												alt='Features split 02'
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='carousel-item' data-bs-interval='5000'>
						<div className='container'>
							<div
								id='carousal2'
								style={{ paddingBottom: '10px' }}
								className={innerClasses}>
								<SectionHeader
									data={sectionHeader3}
									style={{ paddingBottom: '30px' }}
									className='center-content'
								/>
								<Image
									src={require('./../../assets/images/UI-images-table-01.svg')}
									alt='Features split 03'
								/>
							</div>
						</div>
					</div>
				</div>
				<button
					className='carousel-control-prev'
					type='button'
					data-bs-target='#carouselExampleInterval'
					data-bs-slide='prev'>
					<span
						className='carousel-control-prev-icon'
						aria-hidden='true'></span>
					<span className='visually-hidden'>Previous</span>
				</button>
				<button
					className='carousel-control-next'
					type='button'
					data-bs-target='#carouselExampleInterval'
					data-bs-slide='next'>
					<span
						className='carousel-control-next-icon'
						aria-hidden='true'></span>
					<span className='visually-hidden'>Next</span>
				</button>
			</div>
		</div>
	);
};

export default Carousal;
