import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import NewLayout from './layouts/NewLayout';

// Views
import Home from './views/Home';
import Terms from './views/Terms';
import PrivacyPolicy from './views/PrivacyPolicy';

require('dotenv').config();

// Initialize Google Analytics
ReactGA.initialize('UA-194781018-1', { testMode: true });
// process.env.REACT_APP_GA_CODE
const trackPage = (page) => {
	ReactGA.set({ page });
	ReactGA.pageview(page);
};

const App = () => {
	const childRef = useRef();
	let location = useLocation();

	useEffect(() => {
		const page = location.pathname;
		document.body.classList.add('is-loaded');
		childRef.current.init();

		trackPage(page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);
	return (
		<ScrollReveal
			ref={childRef}
			children={() => (
				<Switch>
					<AppRoute exact path="/" component={Home} layout={LayoutDefault} />
					<AppRoute
						exact
						path="/terms-of-service"
						component={Terms}
						layout={NewLayout}
					/>
					<AppRoute
						exact
						path="/privacy-policy"
						component={PrivacyPolicy}
						layout={NewLayout}
					/>
				</Switch>
			)}
		/>
	);
};

export default App;
