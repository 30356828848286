import React from 'react';
import ScrollToTop from '../utils/ScrollToTop';
import SectionHeader from '../components/sections/partials/SectionHeader';
import { SectionSplitProps } from '../utils/SectionProps';
import classNames from 'classnames';
import currentYear from '../utils/common'


const propTypes = {
	...SectionSplitProps.types,
};

const defaultProps = {
	...SectionSplitProps.defaults,
};

const Terms = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	invertMobile,
	invertDesktop,
	alignTop,
	imageFill,
	...props
}) => {
	const outerClasses = classNames(
		'features-split section',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'features-split-inner section-inner',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const sectionHeader = {
		title: 'Terms of Service',
		paragraph: `Last updated on January 1, ${currentYear}`,
	};

	return (
		<section {...props} className={outerClasses}>
			<ScrollToTop />

			<div className="container">
				<div id="fullstack" className={innerClasses}>
					<SectionHeader
						style={{ paddingBottom: '40px' }}
						data={sectionHeader}
						id="terms"
						className="center-content"
					/>
					<p>
						All editorial content and graphics on this Web site are proprietary
						to Trusty Bytes Private Limited. These materials are protected and
						may not be copied, used, reproduced, or transmitted in whole or in
						part without the express written consent of Trusty Bytes, which
						reserves all rights. Re-use of any of Trusty Bytes’s editorial
						content and graphics online for any purpose is strictly prohibited.
						Any unauthorized use of the text, and images may violate copyright,
						trademark, privacy and publicity laws and civil and criminal
						statutes.
					</p>
					<h5 className="mt-0 mb-32">Cookies</h5>
					<p>
						We employ the use of cookies. Most of the modern day interactive web
						sites use cookies to enable us to retrieve user details for each
						visit. Cookies are used in some areas of our site to enable the
						functionality of this area and ease of use for those people
						visiting. Some of our affiliate / advertising partners may also use
						cookies. By using Trusty Bytes’s website you consent to the use of
						cookies in accordance with Trusty Bytes privacy policy.
					</p>
					<h5 className="mt-0 mb-32">Trademarks and Logo</h5>
					<p>
						The “Trusty Bytes” name and logo are trademarks of Trusty Bytes
						Private Limted. All other trademarks used or referred to on this Web
						site are the property of their respective owners. Nothing contained
						on this Web site should be construed as granting, by implication,
						estoppel, or otherwise, any license or right of use of any trademark
						displayed on the Web site without the written permission of Trusty
						Bytes or such third party owners.
					</p>
					<h5 className="mt-0 mb-32">Site Contents and Links</h5>
					<p>
						The information on this site is provided solely for general
						illustrative and instructional purposes only, and does not create a
						business or professional services relationship. Business practice,
						business challenges, technology, laws and regulations vary by
						country and change from time to time, and compliance with such
						standards depends on the particular circumstances. Any reliance on
						the information in this site is solely at the user’s own risk.
					</p>
					<br />
					<p>
						This site contains links to servers maintained by other
						organizations. Trusty Bytes cannot and does not provide any warranty
						about the accuracy or source of information found on any of these
						servers or the content of any file that you might choose to download
						from a third party site. Trusty Bytes assumes no responsibility, and
						shall not be liable for, any damages to, or viruses that may infect,
						user’s computer or other property on account of user’s access to,
						use of, or browsing in the Web site or user’s downloading of any
						materials, data, text, images, video, or audio from the Web site.
					</p>
					<h5 className="mt-0 mb-32">Changes to Terms</h5>
					<p>
						Trusty Bytes reserves the right to modify or amend this Terms and
						Conditions at any time and for any reason. Any changes to this Terms
						and Conditions posted on the Web site apply as soon as they are
						posted. By continuing to use the Web site after any changes are
						posted, user acknowledges acceptance of those changes.
					</p>
				</div>
			</div>
		</section>
	);
};
Terms.propTypes = propTypes;
Terms.defaultProps = defaultProps;

export default Terms;
