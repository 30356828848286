import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import SmoothScroll from '../elements/SmoothScroll';

const propTypes = {
	navPosition: PropTypes.string,
	hideNav: PropTypes.bool,
	hideSignin: PropTypes.bool,
	bottomOuterDivider: PropTypes.bool,
	bottomDivider: PropTypes.bool
};

const defaultProps = {
	navPosition: 'fixed',
	hideNav: false,
	hideSignin: false,
	bottomOuterDivider: false,
	bottomDivider: false
};

const Header = ({
	className,
	navPosition,
	hideNav,
	hideSignin,
	bottomOuterDivider,
	bottomDivider,
	...props
}) => {
	const [isActive, setIsactive] = useState(false);

	const nav = useRef(null);
	const hamburger = useRef(null);

	useEffect(() => {
		isActive && openMenu();
		document.addEventListener('keydown', keyPress);
		document.addEventListener('click', clickOutside);
		return () => {
			document.removeEventListener('keydown', keyPress);
			document.removeEventListener('click', clickOutside);
			closeMenu();
		};
	});

	const openMenu = () => {
		document.body.classList.add('off-nav-is-active');
		nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
		setIsactive(true);
	};

	const closeMenu = () => {
		document.body.classList.remove('off-nav-is-active');
		nav.current && (nav.current.style.maxHeight = null);
		setIsactive(false);
	};

	const keyPress = e => {
		isActive && e.keyCode === 27 && closeMenu();
	};

	const clickOutside = e => {
		if (!nav.current) return;
		if (
			!isActive ||
			nav.current.contains(e.target) ||
			e.target === hamburger.current
		)
			return;
		closeMenu();
	};

	const classes = classNames(
		'site-header',
		bottomOuterDivider && 'has-bottom-divider',
		'shadow-sm',
		className
	);

	return (
		<header {...props} className={classes} style={{ opacity: '0.95' }}>
			<div className='container'>
				<div
					className={classNames(
						'site-header-inner',
						bottomDivider && 'has-bottom-divider'
					)}>
					<SmoothScroll to='header' duration={200}>
						<Logo style={{ marginRight: '200px' }} />
					</SmoothScroll>
					{!hideNav && (
						<>
							<button
								ref={hamburger}
								className='header-nav-toggle'
								onClick={isActive ? closeMenu : openMenu}>
								<span className='screen-reader'>Menu</span>
								<span className='hamburger'>
									<span className='hamburger-inner'></span>
								</span>
							</button>
							<nav
								ref={nav}
								className={classNames('header-nav', isActive && 'is-active')}>
								<div
									className='header-nav-inner'
									style={{ display: 'flex', justifyContent: 'space-between' }}>
									<div>
										<ul
											className={classNames(
												'list-reset text-xs ',
												navPosition && `header-nav-${navPosition}`
											)}>
											<li>
												<SmoothScroll to='header' duration={500}>
													Home
												</SmoothScroll>
											</li>
											<li>
												<SmoothScroll to='our-services' duration={500}>
													What We Offer
												</SmoothScroll>
											</li>
											<li>
												<SmoothScroll to='carousal' duration={500}>
													About Us
												</SmoothScroll>
											</li>
											<li>
												<SmoothScroll to='contact-form' duration={500}>
													Contact Us
												</SmoothScroll>
											</li>
										</ul>
									</div>
									<div>
										{!hideSignin && (
											<ul className='list-reset '>
												<a
													className='hovr-item'
													href='https://www.facebook.com/trustybytes01'
													target='_blank'
													rel='noopener noreferrer'>
													<i
														className='fab fa-facebook-f fa-md '
														// style={{ color: '#2f3037' }}
													></i>
												</a>

												<a
													className='hovr-item'
													href='https://twitter.com/TrustyBytes'
													target='_blank'
													rel='noopener noreferrer'>
													<i
														className='fab fa-twitter fa-md '
														// style={{ color: '#2f3037' }}
													></i>
												</a>

												<a
													className='hovr-item'
													href='https://www.instagram.com/trustybytes/'
													target='_blank'
													rel='noopener noreferrer'>
													<i
														className='fab fa-instagram fa-md '
														// style={{ color: '#2f3037' }}
													></i>
												</a>
												<a
													className='hovr-item'
													href='https://www.linkedin.com/company/trusty-bytes'
													target='_blank'
													rel='noopener noreferrer'>
													<i
														className='fab fa-linkedin-in fa-md'
														// style={{ color: '#2f3037' }}
													></i>
												</a>
											</ul>
										)}
									</div>
								</div>
							</nav>
						</>
					)}
				</div>
			</div>
		</header>
	);
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
